import React from "react"

export default function Home() {
	if (typeof window !== "undefined") {
		// browser code
		fetch('nogrammer.com/homebase')
		.then((response) => response.json())
		.then((json) => {
			console.log(json.movies)
		})
		.catch((error) => {
			console.error(error);
		})
	}

    const jsonContent = 
`{
	"title": "The Basics - Networking",
	"description": "Your app fetched this from a remote endpoint!",
	"movies": [
		{ "id": "1", "title": "Star Wars", "releaseYear": "1977" },
		{ "id": "2", "title": "Back to the Future", "releaseYear": "1985" },
		{ "id": "3", "title": "The Matrix", "releaseYear": "1999" },
		{ "id": "4", "title": "Inception", "releaseYear": "2010" },
		{ "id": "5", "title": "Interstellar", "releaseYear": "2014" }
	]
}`

	return (
		<pre style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
			{ jsonContent }
		</pre>
	)
}
